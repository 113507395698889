.section{
    padding: 70px 0;
}
.section-navbars{
    padding-bottom: 0;
}

.section-full-screen{
    height: 100vh;
}
.section-signup{
    padding-top: 20vh;
}
