.carousel{
    .carousel-control{
        width: 50%;

        &.left,
        &.right{
            background-image: none;
        }

        .material-icons,
        .fa{
            display: none;
        }
    }
    .left{
        cursor: url("../img/arrow-left.png"), url("../img/arrow-left.cur"), default !important;
    }
    .right{
        cursor: url("../img/arrow-right.png"), url("../img/arrow-right.cur"), default !important;
    }

    .carousel-indicators{
        bottom: 5px;

        li,
        .active{
            margin: 11px 10px;
        }

        li{
            background: #FFFFFF;
            @include shadow-2dp();
            @extend .animation-transition-general;
            border-radius: $border-radius-small;
        }
        .active{
            margin-top: 10px;
            @include transform-scale(1.5);
            @include shadow-4dp();
        }
    }

    .carousel-caption{
        padding-bottom: 45px;
        
        .material-icons{
            position: relative;
            top: 5px;
        }
    }

}
