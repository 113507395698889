.info{
    max-width: 360px;
    margin: 0 auto;
    padding: 70px 0 30px;

    .icon{
        color: $gray-color;

        > i{
            font-size: 4.4em;
        }
    }
    .info-title{
        color: $black-color;
        margin: $margin-base * 2 0 $margin-base;
    }
    p{
        color: $gray-color;
    }
}

.icon{
    &.icon-primary{
        color: $brand-primary;
    }
    &.icon-info{
        color: $brand-info;
    }
    &.icon-success{
        color: $brand-success;
    }
    &.icon-warning{
        color: $brand-warning;
    }
    &.icon-danger{
        color: $brand-danger;
    }
}
