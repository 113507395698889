.card {

    display: inline-block;
    position: relative;
    width: 100%;

    .card-height-indicator {
        margin-top: 100%;
    }
    .card-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    border-radius: $border-radius-base;
    color: $mdb-card-body-text;
    background: $mdb-card-body-background;

    @include shadow-2dp();

    .card-image {
        height: 60%;
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            pointer-events: none;
        }
        .card-image-headline {
            position: absolute;
            bottom: 16px;
            left: 18px;
            color: $mdb-card-image-headline;
            font-size: 2em;
        }
    }

    .content{
        padding: 15px;
    }

    .card-body {
        height: 30%;
        padding: 18px;
    }

    .card-footer {
        height: 10%;
        padding: 18px;
        button, a {
            margin: 0 !important;
            position: relative;
            bottom: 25px;
            width: auto;
            &:first-child {
                left: -15px;
            }
        }
    }

    .header{
        @include shadow-big();
        margin: $margin-base;
        border-radius: $border-radius-base;
        padding: $padding-base 0;
        background-color: $white-color;
    }

    .header-primary{
        background: linear-gradient(60deg, $purple-400, $purple-700);
    }
    .header-info{
        background: linear-gradient(60deg, $light-blue-400, $light-blue-700);
    }
    .header-success{
        background: linear-gradient(60deg, $green-400, $green-700);
    }
    .header-warning{
        background: linear-gradient(60deg, $yellow-600, $yellow-700);
    }
    .header-danger{
        background: linear-gradient(60deg, $red-400, $red-700);
    }

    [class*="header-"]{
        color: #FFFFFF;
    }
}

.card-raised{
    @include shadow-big();
}

.card-signup{
    .header{
        @include shadow-big();
        margin-left: 20px;
        margin-right: 20px;
        margin-top: -40px;
        padding: 20px 0;
    }
    .text-divider{
        margin-top: 30px;
        margin-bottom: 0px;
        text-align: center;
    }
    .content{
        padding: 0px 30px 0px 10px;
    }

    .checkbox{
        margin-top: 20px;

        label{
            margin-left: 17px;
        }
        .checkbox-material{
            padding-right: 12px;
        }
    }

    .social-line{
        margin-top: $margin-base;
        text-align: center;

        .btn{
            color: $white-color;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

.card-nav-tabs{
    margin-top: 45px;

    .header{
        margin-top: -$margin-base * 2;
    }
    .nav-tabs{
        background: transparent;
    }
}

.card-plain{
    background: transparent;
    box-shadow: none;

    .header{
        margin-left: 0;
        margin-right: 0;
    }

    .content{
        padding-left: 0;
        padding-right: 0;
    }
}
